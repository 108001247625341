.App {
  text-align: center;
  max-width: 100%;
}

p{
  max-width: 80%;
  margin: 1rem auto;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App .redCount{
  color: red;
  font-weight: bolder;
}
.LogoMoon{
  animation: App-logo-spin infinite 15s linear;
  font-size: 8rem;
  margin: 0;
}
.CameraButton{
  font-size: calc(10px + 10vmin);
  border:none;
  background: none;
  outline:none;
  margin-top: 2rem;
}
.CameraButton:focus{
  border: none;
}
.CameraButton::before{
  content: "📷";
}
.CameraButton:active::before{
  content: "📸";
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
